import React from 'react'

const className =
  'inline-block font-sansSerif font-normal underline antialiased'
export const TextLinkClassName = className

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  > {
  disabled?: boolean
}

const TextLink: React.FC<Props> = ({
  children,
  disabled,
  className,
  ...other
}) => {
  return (
    <span
      className={
        TextLinkClassName +
        (disabled
          ? ` cursor-not-allowed text-text-muted-2x no-underline hover:text-text-muted-2x`
          : ' cursor-pointer text-text-muted hover:text-black') +
        (className ? ` ${className}` : '')
      }
      {...other}
    >
      {children}
    </span>
  )
}

export default TextLink
